








import { Vue, Component } from "vue-property-decorator";
import Rview from "@/components/routerView/index.vue";
@Component({
  components: {
    Rview,
  },
})
export default class extends Vue {
  

}
